@font-face {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto-Regular'), url('./assets/fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Roboto-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto-Bold'), url('./assets/fonts/Roboto-Bold.woff') format('woff');
}

body {
    background: #2B2D40;
    margin: 0;
    font-family: 'Roboto-Regular', sans-serif;
    color: #fff;
}

button {
    cursor: pointer;
}

.content-container {
    height: calc(100vh - 105px);
    background: url('./assets/img/main-bg.png');
    background-size: cover;
}

.sign-in-container {
    min-height: 100vh;
    color: #fff;
    background: url('./assets/img/sign-in-bg.png');
    background-size: cover;
}

.sign-in-logo {
    position: absolute;
    width: 37px;
    top: 9px;
    left: 55px;
}

.sign-in-back-button {
    position: absolute;
    top: -32px;
    left: -16px;
}

.sign-in-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #292C40;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 360px;
    padding: 0 32px 32px 32px;
}

.sign-in-logo-wrapper {
    height: 185px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign-in-logo-border {
    height: 126px;
    width: 126px;
    border-radius: 50%;
    background: #1B1F2E;
    align-items: center;
    justify-content: center;
    display: flex;
}

.sign-in-button-wrapper {
    margin-top: 24px;
    width: 100%;
}

.sign-in-divider {
    width: 100%;
    height: 1px;
    background: red;
    margin: 20px 0;
}

.sign-in-input {
    width: calc(100% - 34px);
    border: 1px solid #6B76A1;
    border-radius: 2px;
    background: transparent;
    font-size: 14px;
    padding: 12px 16px;
    outline: none;
    color: #6B76A1;
}

.sign-in-button {
    border: none;
    background: #A20E3C;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto-Bold', sans-serif;
    color: #fff;
    width: 100%;
}

.sign-in-help-button {
    color: #8697A2;
    font-size: 14px;
    text-decoration: underline;
    margin-top: 10px;
    line-height: 26px;
    cursor: pointer;
}

.sign-in-error-message {
    margin-top: 10px;
    color: #A20E3C;
}

.input-error {
    border-color: #A20E3C;
}

.header {
    display: flex;
    flex-direction: row;
    height: 65px;
}

.header-image-wrapper {
    display: flex;
    width: 143px;
    align-items: center;
    justify-content: center;
}

.header-image {
    width: 38px;
    height: auto;
    margin-left: 10px;
}

.header-tabs {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-end;
}

.header-tabs__inner {
    display: flex;
}

.header-tab {
    display: flex;
    width: 150px;
    height: 33px;
    background: #191F2E;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}

.translation-picker:hover {
    background: #00ADE8;
}

a {
    text-decoration: none;
}

.header-tab-active {
    background: #00ADE8;
}

.header-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 50px;
}

.buttons-divider {
    width: 16px;
}

.button-new {
    border: 2px solid #A20E3C;
    background: #A20E3C;
    padding: 9px 16px;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Roboto-Bold', sans-serif;
    color: #fff;
    border-radius: 2px;
    letter-spacing: 1px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-new-width-100 {
    width: 100%;
}

.button-new-icon {
    height: 15px;
    margin-right: 10px;
}

.outline-white {
    border: 2px solid #fff;
    background: transparent;
}

.frameless-white {
    border: none;
    background: transparent;
}

.subheader {
    background: #1B1F2E;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 50px;
    padding-left: 65px;
}

.subheader-text {
    font-size: 16px;
    font-family: 'Roboto-Bold', sans-serif;
    color: #fff;
    line-height: 19px;
}

.event-info {
    width: 585px;
    height: 85px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #fff;
}

.event-info-date-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 25px;
}

.event-info-date {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 16px;
    margin-left: 10px;
}

.event-info-divider {
    height: 50px;
    width: 1px;
    background: #989898;
}

.event-info-timer-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.event-timer-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.event-timer-element-value {
    font-family: 'Roboto-Bold', sans-serif;
    font-size: 32px;
    color: #16ACE2;
}

.event-timer-element-type {
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 14px;
    color: #8697A2;
}

.event-placeholder-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.event-placeholder-title {
    font-size: 42px;
    font-family: 'Roboto-Regular', sans-serif;
    line-height: 55px;
    color: #fff;
    margin-bottom: 40px;
}

.event-placeholder-event-name {
    font-size: 34px;
    font-family: 'Roboto-Bold', sans-serif;
    line-height: 41px;
    color: #fff;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.event-placeholder-buttons-block {
    margin-top: 24px;
    display: flex;
}

.event-placeholder-sing-in-wrapper {
    width: 300px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.event-placeholder-live-badge {
    background: #A20E3C;
    padding: 2px 7px;
    border-radius: 17px;
    margin-left: 5px;
    font-size: 14px;
    line-height: 17px;
}

.share-dialog-backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.share-dialog-container {
    background: #292C40;
    padding: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.share-dialog-close-button {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
}

.share-dialog-title {
    font-size: 26px;
    line-height: 34px;
    font-family: 'Roboto-Bold', sans-serif;
    color: #fff;
}

.share-dialog-share-link-wrapper {
    display: flex;
    width: 427px;
    margin: 32px 0;
}

.share-dialog-share-link {
    border: 1px solid #6B76A1;
    border-radius: 2px;
    padding: 11px 0 12px 16px;
    font-size: 14px;
    width: 304px;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.copy-link-button {
    width: 107px;
}

.share-dialog-socials-wrapper {
    display: flex;
    width: 216px;
    justify-content: space-between;
}

.alternative-player-container {
    height: 100%;
    width: 100%;
}

.error-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #6B76A1;
    text-align: center;
}

.error-container img {
    margin-bottom: 20px;
}

.error-container a {
    color: #16ACE2;
}

.live-event-stats-container {
    position: absolute;
    top: 10px;
    right: 10px;
    font-family: 'Roboto-Regular', sans-serif;
    display: flex;
}

.live-event-stats-element {
    margin: 4px;
    display: flex;
    padding: 2px 8px;
    font-size: 12px;
    align-items: center;
    font-weight: bold;
    border-radius: 4px;
    justify-content: center;
    background-color: #1B1F2E;
    line-height: 1.5;
}

.live-event-dot {
    width: 8px;
    height: 8px;
    background: linear-gradient(180deg, #E02020 0%, #C11C38 100%);
    margin-right: 4px;
    border-radius: 50%;
}

.live-event-eye-icon {
    margin-right: 5px;
}

iframe {
    border: none;
}

button:hover:not(.outline-white):not(.frameless-white) {
    background: #00ADE8;
    border-color: #00ADE8;
}

.lang-picker-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.lang-picker-container {
    background: #292c40;
    border: 1px solid #ba1a48;
}

.lang-picker-popper {
    z-index: 2;
}

.lang-picker-element {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.lang-picker-flag {
    margin-right: 10px;
}

/* Vimeo player styles */
.vimeo-outer {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.vimeo-root {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}

.vimeo-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.vimeo-inner > iframe {
    width: 100%;
    height: 100%;
}
/* Vimeo player styles */

/* Reactor overrides */
.reactour {
    border: 1px solid #189dd0;
    font-weight: lighter;
    background: #2B2D40 !important;
    padding: 32px !important;
    font-size: 16px !important;
    max-width: 277px !important;
}

.reactour [data-tour-elem="badge"], .reactour [data-tour-elem="navigation"], .reactour [data-tour-elem="left-arrow"] {
    display: none;
}

.reactour [data-tour-elem="right-arrow"] {
    margin: 0;
    width: 100%;
    background: #A20E3C;
    color: white;
    padding: 12px;
    font-size: 16px;
}
.reactour [data-tour-elem="right-arrow"] > span {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bolder;
}

.reactour .reactour__close {
    color: white;
    top: 14px;
    right: 14px;
    height: 12px;
    width: 12px;
}

.reactour .reactour__close:hover {
    background: none !important;
    color: white !important;
}
/* Reactor overrides */
.zoom-simple__container {
    background: #0F121799;
    height: calc(100vh - 140px - 140px - 65px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 140px;
    padding-bottom: 100px;
}

.zoom-simple__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
}

.zoom-simple__header{
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0px;
    margin-bottom: 40px;
    text-align: center;
}

.zoom-simple__button{
    max-width: 200px;
}

@media (max-width: 900px) {
    .event-info {
        flex-direction: column;
        width: calc(312px - 64px);
        padding: 17px 32px 10px 32px;
    }

    .event-info-divider {
        width: calc(100% - 64px);
        height: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .event-info-date-wrapper {
        margin-left: 0;
    }

    .event-info-timer-wrapper {
        width: 100%;
    }

    .event-timer-element-value {
        font-size: 22px;
    }

    .event-timer-element-type {
        font-size: 11px;
    }

    .event-placeholder-buttons-block {
        flex-direction: column-reverse;
        align-items: center;
    }

    .event-placeholder-buttons-block button {
        width: fit-content;
        margin: 8px 0;
    }

    .event-placeholder-title {
        line-height: 43px;
        font-size: 32px;
        text-align: center;
        padding: 0 5px;
    }

    .subheader {
        padding: 0 16px;
    }

    .subheader .button-new {
        font-size: 0;
        padding: 0;
    }

    .subheader .button-new .button-new-icon {
        margin: 0;
    }

    .header-image-wrapper {
        width: unset;
    }

    .header-buttons {
        padding-right: 16px;
        width: 100%;
        justify-content: flex-end;
    }

    .header-buttons .button-new {
        font-size: 14px;
        line-height: 17px;
    }

    .header-tabs {
        height: 34px;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }

    .header-tabs::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }


    .header-tab {
        padding: 0 35px;
        width: unset;
    }

    .content-container {
        height: calc(100vh - 105px - 34px);
    }

    .sign-in-logo {
        top: 9px;
        left: 25px;
    }

    .sign-in-form {
        width: 296px;
    }

    .sign-in-back-button {
        font-size: 14px;
    }

    .sign-in-back-button img {
        height: 12px;
    }

    .share-dialog-container {
        width: calc(355px - 80px);
    }

    .share-dialog-share-link-wrapper {
        width: unset;
    }

    .share-dialog-share-link {
        width: 180px;
    }

    .zoom-simple__container {
        padding-top: 80px;
        height: calc(100vh - 140px - 80px - 65px);

    }

    .zoom-simple__header{
        font-size: 24px;
    }
}
